import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppVoice = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our Future! Vote in the EU elections - here's how 🗳"
      description="Click the link and send a message to friends on WhatsApp - give them a voice! ➡️"
      shareImage="student"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey! You know we have the right to vote in the EU elections right?

Let's send this message to all our friends and WhatsApp groups in the UK - and make voting go viral!
Click here: https://saveourfuture.uk/voice

*Register to vote by May 7th*
Follow the simple steps here (takes 2 mins):
https://saveourfuture.uk/getvoting

Do it now! Top tip: apply for a postal vote too, especially if you don't know where you'll be on May 23rd
(People are 60% more likely to vote with a postal vote!)

*IMPORTANT!*
💥 Send this message to your friends and groups, now! 💥
Click here: https://saveourfuture.uk/voice

🗣 *THIS TIME, OUR VOICE COUNTS* 🗣`}
    />
    <Interstitial />
    <Disclaimer />
  </Layout>
);

WhatsAppVoice.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppVoice;

export const query = graphql`
  query VoiceQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
